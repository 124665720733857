import { NgModule } from '@angular/core';
import { NgxUpperCaseDirective } from './ngx-upper-case.directive';

@NgModule({
  imports: [],
  declarations: [NgxUpperCaseDirective],
  exports: [NgxUpperCaseDirective],
})
export class NgxUpperCaseDirectiveModule {
}
